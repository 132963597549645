const BREAKPOINTS = {
  LG_MIN: 1300.01,
  MD_MAX: 1300,
  MD_MIN: 800.01,
  SM_MAX: 800,
  SM_MIN: 600.01,
  TY_MAX: 600,
} as const;

export enum MediaType {
  LG,
  MD,
  SM,
  TY,
}

export function getBreakpointFromWidth(width: number): MediaType {
  if (width >= BREAKPOINTS.LG_MIN) return MediaType.LG;
  if (width <= BREAKPOINTS.MD_MAX && width >= BREAKPOINTS.MD_MIN)
    return MediaType.MD;
  if (width <= BREAKPOINTS.SM_MAX && width >= BREAKPOINTS.SM_MIN)
    return MediaType.SM;
  if (width <= BREAKPOINTS.TY_MAX) return MediaType.TY;
  return MediaType.MD;
}
